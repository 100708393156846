<template>
  <div class="legal">
    <div class="background"></div>
    <v-container>
      <v-row class="mb-10 justify-center">
        <v-col class="content_style px-10 py-11" cols="12" sm="8">
          <img class="hidden-sm-and-down" src="/static/img/common/legal2.png?v=20221027" alt="">
          <img class="hidden-md-and-up" src="/static/img/common/legal2_mobile.png?v=20221027" alt="">
          <!-- <p class="titleName text-h3 mb-10 text-center titleName">資金決済法に基づく表記</p>
          <h2>1. サービス名</h2>
          <p>麻雀一番街</p>
          <h2>2. 発行事業者</h2>
          <p>Lancelot Tech Ltd.</p>
          <p>Vistra Corporate Services, Suite 23, 1st Floor, Eden Plaza, Mahe, 999126, SYC</p>
          <h2>3.購入限度額等（支払可能金額等）</h2>
          <p>上限はございません。</p>
          <h2>4. 有効期限</h2>
          <p>有効期限はございません。</p>
          <p>ただし、ゲーム内のデータを消去された場合、未使用残高は全て失効されます。</p>
          <h2>5. お問い合わせ先</h2>
          <p>所在地：Vistra Corporate Services, Suite 23, 1st Floor, Eden Plaza, Mahe, 999126, SYC</p>
          <p>お問い合わせはriichicitysupport@mahjong-jp.comまでお願いいたします。</p>
          <h2>6. 利用できる場所</h2>
          <p>一番券：アプリ『麻雀一番街』サービス内でのみご利用いただけます。</p>
          <h2>7. 利用上の注意</h2>
          <p>※原則、商品販売後の払い戻しはいたしません。ただしサービスの提供を終了する場合は、この限りではありません。</p>
          <p>※システム障害時やメンテナンス等により、一時的に商品の利用ができない場合があります。</p>
          <p>※商品の利用条件は利用規約をご確認ください。</p>
          <h2>8. 未使用残高確認方法</h2>
          <p>『麻雀一番街』サービス内における「ロビー」及び「コンビニ」画面上部で一番券の内訳を確認することができます。</p>
          <h2>9. 利用規約</h2>
          <p>利用規約は<a href="/termsofService">こちら</a></p>
          <h2>10. 不正取引による損失の補償等に関する方針</h2>
          <p>当社は、前払式支払手段の不正利用（前払式支払手段の保有者の意思に反した、権限を有しない者の指図による前払式支払手段の利用のことをいいます）により、当該保有者に生じた損失について、利用規約又は法令に基づき当社が責任を負う場合を除き、一切責任を負いかねますので、あらかじめご了承ください。</p>
          <p>なお、不正取引に関する通報、ご相談につきましては、上記のお問い合わせ先にお願いいたします。</p> -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
.legal{
  position: relative; 
  .background{
    position: fixed;
    display: block;
    top:0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-image: url('/static/img/common/policyBackground.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 0;
  }
  .content_style{
    z-index: 3;
  }
  img {
    width: 100%;
    height: auto;
  }
  // .titleName {
  //   font-size: 50px;
  //   color: #ffffff; 
  //   font-weight: 900;
  //   text-indent: 0em;
  // }
  // h2 {
  //   color: #ffffff; 
  //   font-weight: 700;
  //   margin: 60px 0 30px 0;
  //   font-size: 32px;
  //   text-indent: 0;
  // }
  // p {
  //   color: #ffffff; 
  //   text-indent: 1em;
  //   font-weight: 500;
  // }
}
</style>